@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

.mainShowCaseRow{
    width: 100%;
}

.showCaseImg0{
    /* text-align: center; */
    /* width: 100%; */
    height: 20rem;
    /* background-color: black; */
    color: black;
    margin-top:-1.4rem;
    background: linear-gradient( to right, #30303078, #3030306d),url(../../Images/homeShowCase.jpg);
    background-position: top;
    background-size: cover;
}

/* ShowCase title */
.showCaseTitle{
    height: auto;
    width: 90%;
    padding-top: 10.4rem;
    padding-left: .4rem;
    text-align: left;
}
.introTitle{
    font-family: 'Varela Round', sans-serif;
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    color: #D9CEC5;
}

/* Home showcase Message */
.showCaseMessage{
    font-family: 'Varela Round', sans-serif;
    font-size: 16px;
    font-weight: lighter;
    /* text-transform: capitalize; */
    color: #D9CEC5;
}


.healthyChildShowCase{
    background-image: linear-gradient(to right, #30303078, #3030306d), url(../../Images/healthy\ child1.jpg);
    background-position: center;
    background-size: cover;
    height: 40rem;
    width: 100vw;
    margin-top: 14rem;
    /* position: relative; */
}

.healthyChildrenText,.aslText{
    padding-top: 10rem;
    /* padding-left: 20rem; */
    width: 20rem;
    text-align: center;
}
.healthyChildrenTitle, .aslTitle{
    color: #D9CEC5;
    font-family: 'Varela Round', sans-serif;
    font-weight: lighter;
    font-size: 18px;
    width: 12rem;
    margin-left: 4rem;
    text-transform: capitalize;
}
.healthyChildrenDesc, .aslDesc{
    font-family: 'Varela Round', sans-serif;
    font-weight: lighter;
    font-size: 14px;
    width: 19rem;
    margin-left: .5rem;
    color: #d9cec5;
    line-height: 26px;
}

.AslShowCase{
    background-image: linear-gradient(to right, #30303078, #3030306d), url(../../Images/aslkid.jpeg);
    background-position: center;
    background-size: cover;
    height: 40rem;
    width: 100vw;
}


/* media Queries */

@media only screen and (max-width: 320px){
    .showCaseTitle{
        /* width: 18rem; */
        /* padding-top: 12rem; */
        /* padding-left: 1rem; */
    }

    .healthyChildrenTitle, .aslTitle{
        margin-top: 3rem;
        font-size: 16px;
    }
    .healthyChildrenDesc, .aslDesc{
        width: 19rem;
        font-size: 12px;
    }
}

@media only screen and (min-width: 375px){
    .showCaseTitle{
        width: 18rem;
        padding-top: 10rem;
        padding-left: 1rem;
    }
    
    .healthyChildrenTitle, .aslTitle{
        width: 22rem;
        margin-top: 4.3rem;
        margin-left: .5rem;
        font-size: 20px;
    }
    .healthyChildrenDesc, .aslDesc{
        width: 22rem;
        /* margin-top: 2rem; */
    }
}

@media only screen and (min-width: 414px){
    .healthyChildrenTitle, .aslTitle{
        width: 24rem;
        font-size: 21px;
        margin-left: .8rem;
    }
    .healthyChildrenDesc, .aslDesc{
        width: 25rem;
        margin-left: .4rem;
        line-height: 28px;
    }
}

@media only screen and (min-width: 425px) {
    .showCaseTitle{
        width: 22rem;
        padding-top: 10rem;
    }
    .introTitle{
        font-size: 22px;
    }

    .showCaseMessage{
        font-family: 'Varela Round', sans-serif;
        font-size: 16px;
        font-weight: lighter;
        /* text-transform: capitalize; */
        color: #D9CEC5;
        width: 18rem;
    }

    .healthyChildrenText,.aslText{
        padding-top: 10rem;
        /* padding-left: 20rem; */
        width: 24rem;
        text-align: center;
    }
    .healthyChildrenTitle, .aslTitle{
        color: #D9CEC5;
        font-family: 'Varela Round', sans-serif;
        font-weight: lighter;
        font-size: 22px;
        width: 18rem;
        margin-left: 4rem;
        text-transform: capitalize;
    }
    .healthyChildrenDesc, .aslDesc{
        font-family: 'Varela Round', sans-serif;
        font-weight: lighter;
        font-size: 14px;
        width: 24rem;
        margin-left: .7rem;
        color: #d9cec5;
        line-height: 26px;
    }
}
@media only screen and (min-width: 768px) {
    
    .showCaseImg0{
        /* text-align: center; */
        /* width: 100%; */
        height: 35rem;
    }
    /* ShowCase title */
    .showCaseTitle{
        width: 30rem;
        padding-top: 18rem;
    }

    .introTitle{
        font-size: 32px;
    }
    .showCaseMessage{
        font-family: 'Varela Round', sans-serif;
        font-size: 22px;
        font-weight: lighter;
        /* text-transform: capitalize; */
        color: #D9CEC5;
        width: 26rem;
    }
    .healthyChildShowCase, .AslShowCase{
        margin-top: 6rem;
    }
    .healthyChildrenText,.aslText{
        padding-top: 10rem;
        /* padding-left: 20rem; */
        width: 100%;
        text-align: center;
    }
    .healthyChildrenTitle, .aslTitle{
        color: #D9CEC5;
        font-family: 'Varela Round', sans-serif;
        font-weight: lighter;
        font-size: 36px;
        width: 100%;
        margin-left: 0rem;
        text-transform: capitalize;
    }
    .healthyChildrenDesc, .aslDesc{
        font-family: 'Varela Round', sans-serif;
        font-weight: lighter;
        font-size: 26px;
        width: 90%;
        margin-left: 2rem;
        margin-top: 2rem;
        color: #d9cec5;
        line-height: 32px;

    }
}

@media only screen and (min-width: 992px) {
    .showCaseImg0{
        height: 34rem;
    }
    
    /* ShowCase title */
    .showCaseTitle{
        width: 40rem;
        padding-top: 16rem;
    }
    .introTitle{
        font-size: 44px;
    }

    .showCaseMessage{
        font-family: 'Varela Round', sans-serif;
        font-size: 26px;
        font-weight: lighter;
        /* text-transform: capitalize; */
        color: #D9CEC5;
        width: 34rem;
    }
    .healthyChildrenDesc, .aslDesc{
        font-size: 30px;
        margin-left: 3rem;
        line-height: 36px;
    }
}

@media only screen and (min-width: 1200px) {
    .showCaseImg0{
        height: 40rem;
    }
    
    /* ShowCase title */
    .showCaseTitle{
        width: 48rem;
        padding-top: 20rem;
    }
    .introTitle{
        font-size: 54px;
    }
    .showCaseMessage{
        font-family: 'Varela Round', sans-serif;
        font-size: 32px;
        font-weight: lighter;
        /* text-transform: capitalize; */
        color: #D9CEC5;
        width: 44rem;
    }
    .healthyChildrenTitle, .aslTitle{
        font-size: 50px;
    }
    .healthyChildrenDesc, .aslDesc{
        font-size: 32px;
        line-height: 40px;

    }
}

@media only screen and (min-width: 1440px) {
    .showCaseImg0{
        height: 48rem;
    }
    
    /* ShowCase title */
    .showCaseTitle{
        width: 54rem;
        padding-top: 22rem;
        margin-left: 1.6rem;
    }
    .introTitle{
        font-size: 56px;
    }
    .showCaseMessage{
        font-family: 'Varela Round', sans-serif;
        font-size: 36px;
        font-weight: lighter;
        /* text-transform: capitalize; */
        color: #D9CEC5;
        width: 54rem;
    }
}

@media only screen and (min-width: 2560px) {
    .showCaseImg0{
        height: 80rem;
    }
    
    /* ShowCase title */
    .showCaseTitle{
        width: 72rem;
        padding-top: 40rem;
        margin-left: 3rem;
    }
    .introTitle{
        font-size: 82px;
        
    }
    .showCaseMessage{
        font-family: 'Varela Round', sans-serif;
        font-size: 52px;
        font-weight: lighter;
        /* text-transform: capitalize; */
        color: #D9CEC5;
        width: 72rem;
    }
    .healthyChildShowCase{
        height: 60rem;
    }

    .healthyChildrenText,.aslText{
        padding-top: 16rem;
    }
    
    .healthyChildrenTitle, .aslTitle{
        font-size: 54px;
    }
    .healthyChildrenDesc, .aslDesc{
       width: 70%;
       font-size:40px;
       line-height: 60px;
       margin-left: 24rem;
    }
    
    .AslShowCase{
        height: 60rem;
    }
}