@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');


.footerContainer{
    width: 100%;
    text-align:center;
    background-color:  #bdb3ab;
}
.footerCopyRight{
    font-family: 'Varela Round', sans-serif;
    padding: 10px 0 10px 0;
    margin-bottom: 0;
    font-weight: 300;
    text-transform: capitalize;
}

/* Media Queries */
@media only screen and (max-width: 320px){
    
}


@media only screen and (min-width: 411px){
 
}

@media only screen and (min-width: 425px){
 
}

@media only screen and (min-width: 768px){
 
}

@media only screen and (min-width: 834px){
  
}

@media only screen and (min-width: 1024px){
  
}

@media only screen and (min-width: 1280px){
  .footerContainer{
      margin-top: 10rem;
  }
}
@media only screen and (min-width: 1400px){
  .footerContainer{
      margin-top: 4rem;
  }
}

@media only screen and (min-width: 1425px){
 
}
@media only screen and (min-width: 1440px){
   
}
@media only screen and (min-width: 2560px){
    .footerCopyRight{
        font-size: 34px;
    }
}