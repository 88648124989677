@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.landingContainer {
    text-align: center;
    width:100%;
  }
  /* Anime Div Style */
  .animeDiv0{
    height: 200px;
  }
   .companyLogo{
     height: 20rem;
     width: 20rem;
   }
   .logoContainer{
    margin-top: 30%;
   }
   /* Anime Home Button Container div */
   .animeHmeBtnDiv0{
      margin-top:40px;
      margin-left: 100px;
      width: 100%;
   }
  
   .landingHomeBtn{
     text-transform: capitalize;
     font-size:14px;
     text-decoration: none;
     color: #8b775d;
   }

   /* Media Queries */
   @media only screen and (max-width: 320px){
     .logoContainer{
       margin-top: 6rem;
     }
   }


   @media only screen and (min-width: 411px){
    
    .logoContainer{
      margin-top: 17rem;
    }
   }

   @media only screen and (min-width: 425px){
    
    .logoContainer{
      width: 0px;
      margin-left: 20rem;
    }
   }

   @media only screen and (min-width: 768px){
    .animeDiv0{
      height: 42vh;
    }  
    
    .companyLogo{
        height: 40rem;
        width: 40rem;
      }

      .logoContainer{
        margin-left: 4.4rem;
      }
      .animeHmeBtnDiv0{
        margin-top: 0px;
        margin-left: 27rem;
        width: 200px;
      }
      .landingHomeBtn{
        font-size: 20px;
      }
   }

   @media only screen and (min-width: 834px){
     .logoContainer{
       margin-left: 6.5rem;
     }
   }

   @media only screen and (min-width: 1024px){
     .animeDiv0{
       height: 34vh;
     }
     .logoContainer{
       margin-top: 20rem;
       margin-left: 12rem;
     }
   }

   @media only screen and (min-width: 1280px){
     .animeDiv0{
       height: 48vh;
     }
     .logoContainer{
       margin-top: 12rem;
       margin-left: 20rem;
     }
   }
   @media only screen and (min-width: 1400px){
      .animeDiv0{
        height: 60vh;
      }

      .logoContainer{
        margin-top: 2rem;
        margin-left: 20rem;
      }
   }

   @media only screen and (min-width: 1425px){
    .animeDiv0{
      height: 60vh;
    }

    .logoContainer{
      margin-top: 4rem;
      margin-left: 20rem;
    }
 }
   @media only screen and (min-width: 1440px){
      .logoContainer{ 
        margin-top: 4rem;
        margin-left: 24rem;
      }
   }
   @media only screen and (min-width: 2560px){
    .animeDiv0{
      height: 45vh;
    }    
    
    .logoContainer{
        margin-top: 18rem;
        margin-left: 50rem;
      }
      .companyLogo{
        height:60vh;
        width: 60vh;
      }
      .animeHmeBtnDiv0{
        margin-left: 40rem;
      }
      .landingHomeBtn{
        font-size: 34px;
      }
  }
   /* @media only screen and (min-width: 3840px){
      .animeDiv0{
        height: 20vh;
      }
      .logoContainer{
        margin-top: 38rem;
      }
   } */
#appContainer{
    padding-right: 0px;
    padding-left: 0px;
}
.gridDisplay{
    display: grid;
    grid-template-columns: auto;
    grid-gap: 16px;
    /* grid-row-gap: 10px; */
    width: 100%;
}


.mainShowCaseRow{
    width: 100%;
}

.showCaseImg0{
    /* text-align: center; */
    /* width: 100%; */
    height: 20rem;
    /* background-color: black; */
    color: black;
    margin-top:-1.4rem;
    background: linear-gradient( to right, #30303078, #3030306d),url(/static/media/homeShowCase.488fd1aa.jpg);
    background-position: top;
    background-size: cover;
}

/* ShowCase title */
.showCaseTitle{
    height: auto;
    width: 90%;
    padding-top: 10.4rem;
    padding-left: .4rem;
    text-align: left;
}
.introTitle{
    font-family: 'Varela Round', sans-serif;
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    color: #D9CEC5;
}

/* Home showcase Message */
.showCaseMessage{
    font-family: 'Varela Round', sans-serif;
    font-size: 16px;
    font-weight: lighter;
    /* text-transform: capitalize; */
    color: #D9CEC5;
}


.healthyChildShowCase{
    background-image: linear-gradient(to right, #30303078, #3030306d), url("/static/media/healthy child1.be63e493.jpg");
    background-position: center;
    background-size: cover;
    height: 40rem;
    width: 100vw;
    margin-top: 14rem;
    /* position: relative; */
}

.healthyChildrenText,.aslText{
    padding-top: 10rem;
    /* padding-left: 20rem; */
    width: 20rem;
    text-align: center;
}
.healthyChildrenTitle, .aslTitle{
    color: #D9CEC5;
    font-family: 'Varela Round', sans-serif;
    font-weight: lighter;
    font-size: 18px;
    width: 12rem;
    margin-left: 4rem;
    text-transform: capitalize;
}
.healthyChildrenDesc, .aslDesc{
    font-family: 'Varela Round', sans-serif;
    font-weight: lighter;
    font-size: 14px;
    width: 19rem;
    margin-left: .5rem;
    color: #d9cec5;
    line-height: 26px;
}

.AslShowCase{
    background-image: linear-gradient(to right, #30303078, #3030306d), url(/static/media/aslkid.6cd7f5cf.jpeg);
    background-position: center;
    background-size: cover;
    height: 40rem;
    width: 100vw;
}


/* media Queries */

@media only screen and (max-width: 320px){
    .showCaseTitle{
        /* width: 18rem; */
        /* padding-top: 12rem; */
        /* padding-left: 1rem; */
    }

    .healthyChildrenTitle, .aslTitle{
        margin-top: 3rem;
        font-size: 16px;
    }
    .healthyChildrenDesc, .aslDesc{
        width: 19rem;
        font-size: 12px;
    }
}

@media only screen and (min-width: 375px){
    .showCaseTitle{
        width: 18rem;
        padding-top: 10rem;
        padding-left: 1rem;
    }
    
    .healthyChildrenTitle, .aslTitle{
        width: 22rem;
        margin-top: 4.3rem;
        margin-left: .5rem;
        font-size: 20px;
    }
    .healthyChildrenDesc, .aslDesc{
        width: 22rem;
        /* margin-top: 2rem; */
    }
}

@media only screen and (min-width: 414px){
    .healthyChildrenTitle, .aslTitle{
        width: 24rem;
        font-size: 21px;
        margin-left: .8rem;
    }
    .healthyChildrenDesc, .aslDesc{
        width: 25rem;
        margin-left: .4rem;
        line-height: 28px;
    }
}

@media only screen and (min-width: 425px) {
    .showCaseTitle{
        width: 22rem;
        padding-top: 10rem;
    }
    .introTitle{
        font-size: 22px;
    }

    .showCaseMessage{
        font-family: 'Varela Round', sans-serif;
        font-size: 16px;
        font-weight: lighter;
        /* text-transform: capitalize; */
        color: #D9CEC5;
        width: 18rem;
    }

    .healthyChildrenText,.aslText{
        padding-top: 10rem;
        /* padding-left: 20rem; */
        width: 24rem;
        text-align: center;
    }
    .healthyChildrenTitle, .aslTitle{
        color: #D9CEC5;
        font-family: 'Varela Round', sans-serif;
        font-weight: lighter;
        font-size: 22px;
        width: 18rem;
        margin-left: 4rem;
        text-transform: capitalize;
    }
    .healthyChildrenDesc, .aslDesc{
        font-family: 'Varela Round', sans-serif;
        font-weight: lighter;
        font-size: 14px;
        width: 24rem;
        margin-left: .7rem;
        color: #d9cec5;
        line-height: 26px;
    }
}
@media only screen and (min-width: 768px) {
    
    .showCaseImg0{
        /* text-align: center; */
        /* width: 100%; */
        height: 35rem;
    }
    /* ShowCase title */
    .showCaseTitle{
        width: 30rem;
        padding-top: 18rem;
    }

    .introTitle{
        font-size: 32px;
    }
    .showCaseMessage{
        font-family: 'Varela Round', sans-serif;
        font-size: 22px;
        font-weight: lighter;
        /* text-transform: capitalize; */
        color: #D9CEC5;
        width: 26rem;
    }
    .healthyChildShowCase, .AslShowCase{
        margin-top: 6rem;
    }
    .healthyChildrenText,.aslText{
        padding-top: 10rem;
        /* padding-left: 20rem; */
        width: 100%;
        text-align: center;
    }
    .healthyChildrenTitle, .aslTitle{
        color: #D9CEC5;
        font-family: 'Varela Round', sans-serif;
        font-weight: lighter;
        font-size: 36px;
        width: 100%;
        margin-left: 0rem;
        text-transform: capitalize;
    }
    .healthyChildrenDesc, .aslDesc{
        font-family: 'Varela Round', sans-serif;
        font-weight: lighter;
        font-size: 26px;
        width: 90%;
        margin-left: 2rem;
        margin-top: 2rem;
        color: #d9cec5;
        line-height: 32px;

    }
}

@media only screen and (min-width: 992px) {
    .showCaseImg0{
        height: 34rem;
    }
    
    /* ShowCase title */
    .showCaseTitle{
        width: 40rem;
        padding-top: 16rem;
    }
    .introTitle{
        font-size: 44px;
    }

    .showCaseMessage{
        font-family: 'Varela Round', sans-serif;
        font-size: 26px;
        font-weight: lighter;
        /* text-transform: capitalize; */
        color: #D9CEC5;
        width: 34rem;
    }
    .healthyChildrenDesc, .aslDesc{
        font-size: 30px;
        margin-left: 3rem;
        line-height: 36px;
    }
}

@media only screen and (min-width: 1200px) {
    .showCaseImg0{
        height: 40rem;
    }
    
    /* ShowCase title */
    .showCaseTitle{
        width: 48rem;
        padding-top: 20rem;
    }
    .introTitle{
        font-size: 54px;
    }
    .showCaseMessage{
        font-family: 'Varela Round', sans-serif;
        font-size: 32px;
        font-weight: lighter;
        /* text-transform: capitalize; */
        color: #D9CEC5;
        width: 44rem;
    }
    .healthyChildrenTitle, .aslTitle{
        font-size: 50px;
    }
    .healthyChildrenDesc, .aslDesc{
        font-size: 32px;
        line-height: 40px;

    }
}

@media only screen and (min-width: 1440px) {
    .showCaseImg0{
        height: 48rem;
    }
    
    /* ShowCase title */
    .showCaseTitle{
        width: 54rem;
        padding-top: 22rem;
        margin-left: 1.6rem;
    }
    .introTitle{
        font-size: 56px;
    }
    .showCaseMessage{
        font-family: 'Varela Round', sans-serif;
        font-size: 36px;
        font-weight: lighter;
        /* text-transform: capitalize; */
        color: #D9CEC5;
        width: 54rem;
    }
}

@media only screen and (min-width: 2560px) {
    .showCaseImg0{
        height: 80rem;
    }
    
    /* ShowCase title */
    .showCaseTitle{
        width: 72rem;
        padding-top: 40rem;
        margin-left: 3rem;
    }
    .introTitle{
        font-size: 82px;
        
    }
    .showCaseMessage{
        font-family: 'Varela Round', sans-serif;
        font-size: 52px;
        font-weight: lighter;
        /* text-transform: capitalize; */
        color: #D9CEC5;
        width: 72rem;
    }
    .healthyChildShowCase{
        height: 60rem;
    }

    .healthyChildrenText,.aslText{
        padding-top: 16rem;
    }
    
    .healthyChildrenTitle, .aslTitle{
        font-size: 54px;
    }
    .healthyChildrenDesc, .aslDesc{
       width: 70%;
       font-size:40px;
       line-height: 60px;
       margin-left: 24rem;
    }
    
    .AslShowCase{
        height: 60rem;
    }
}
.aboutUsContainer{
    width: 100%;
    margin-top: 4rem;
}
.aboutUsWrapper{
    height: 15rem;
    width: 90vw;
    text-align: center;
    margin-top: 3rem;
    margin-left: 1rem;
    box-shadow: 4px 20px 20px 20px rgba(117, 117, 117, 0.178);
    padding-top:2rem;
    background: url(/static/media/tanTexture.f2ed28cb.png);
    background-position: top;
    background-size: cover;
}
.aboutUsTitle{
    font-family: 'Varela Round', sans-serif;
    font-weight: 400;
    font-size: 12px;
}

.aboutUs, .aboutUs2{
    font-family: 'Varela Round', sans-serif;
    font-size: 12px;
}

@media only screen and (max-width: 320px){
    .aboutUsWrapper{
       height: 15rem;
    }
}

@media only screen and (min-width: 375px){
    .aboutUsWrapper{
        padding: 10px;
    }
    .aboutUsTitle{
        margin-top: 1rem;
    }
    .aboutUs, .aboutUs2{
        margin-top: 2rem;
    }
}

@media only screen and (min-width: 414px){
    .aboutUsWrapper{
       margin-left: 1.3rem;
    }
}

@media only screen and (min-width: 425px){
    .aboutUsWrapper{
       margin-left: 1rem;
    }
}

@media only screen and (min-width: 600px) {
    .aboutUsWrapper{
        width: 94vw;
        margin-left: .7rem;
    }
}

@media only screen and (min-width: 768px) {
    .aboutUsWrapper{
        margin-left: 1rem;
        height: 18rem;
    }
    .aboutUsTitle{
        font-size: 22px;
    }
    
    .aboutUs, .aboutUs2{
        font-size: 18px;
    }
}

@media only screen and (min-width: 992px) {
    .aboutUsWrapper{
        height: 20rem;
        margin-left: 1.5rem;
    }
    .aboutUsTitle{
        font-size: 24px;
    }
    
    .aboutUs, .aboutUs2{
        font-size: 20px;
        margin-top: 2.6rem;
        margin-left: 6rem;
        width: 44rem;
    }
}

@media only screen and (min-width: 1200px) {
    .aboutUsWrapper{
        height: 20rem;
        margin-left: 2.4rem;
    }
    .aboutUsTitle{
        font-size: 30px;
    }
    
    .aboutUs, .aboutUs2{
        font-size: 24px;
        margin-top: 2.6rem;
        margin-left: 4rem;
        width: 58rem;
    }
}

@media only screen and (min-width: 1400px){
    .aboutUsWrapper{
        height: 24rem;
        margin-left: 4rem;
    }
    .aboutUs, .aboutUs2{
        margin-left: 10rem;
    }
}


@media only screen and (min-width: 1440px) {
    .aboutUsWrapper{
        height: 24rem;
        margin-left: 2.8rem;

    }
    .aboutUsTitle{
        font-size: 32px;
    }
    
    .aboutUs, .aboutUs2{
        font-size: 26px;
        margin-top: 2.6rem;
        margin-left: 8.2rem;
        width: 64rem;
    }
}

@media only screen and (min-width: 2560px) {
    .aboutUsWrapper{
        height: 36rem;
        margin-left: 15rem;
        width: 80vw;
    }
    .aboutUsTitle{
        font-size: 50px;
        margin-top: 4rem;
    }
    
    .aboutUs, .aboutUs2{
        font-size: 36px;
        margin-top: 4rem;
        margin-left: 18rem;
        width: 94rem;
    }
}
.infoCardsContainer{
    width: 100%;
    height: 120rem;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-evenly;
    margin-top: 8rem;
    margin-left: 2rem;
    /* z-index: 1; */
}
.card{
    height: 42rem;
    width: 16rem;
    border: 2px solid #F2E7DC;
    /* margin-left: 1.1rem; */
    margin-top: 4rem;
    text-align: center;
}

.cardImg{
    width: 100%;
    height: 14rem;

}
.cardImg1{
    background-image: linear-gradient(to right, #30303078, #3030306d),url("/static/media/hispanic senior.ecc23d1f.jpg");
    background-position: top;
    background-size: cover;
    
}
.cardImg2{
    background-image: linear-gradient(to right, #30303078, #3030306d),url(/static/media/stethoscope2.bc4c962f.jpg);
    background-position: top;
    background-size: cover;
}
.cardImg3{
    background-image: linear-gradient(to right, #30303078, #3030306d),url("/static/media/asian senior.62afbf67.jpg");
    background-position: top;
    background-size: cover;
}

.cardText{
    width: 100%;
    height: 40rem;
    margin-top: 20px;
    text-align: center;
}
.cardTitle{
    font-family: 'Varela Round', sans-serif;
    font-weight: 400;
    font-size: 22px;
    margin-left: 1.5rem;
    margin-top: 20px;
}
.cardDesc{
    font-family: 'Varela Round', sans-serif;
    font-size: 14px;
    line-height: 30px;
    width: 15rem;
    margin-left: .3rem;
    margin-top: 30px;
}

/* media Queries */

@media only screen and (max-width: 320px){
    .card{
        width: 18rem;
    }
    .infoCardsContainer{
        margin-left: 1rem;
    }
    .cardTitle{
        margin-left: 0rem;
    }
    .cardDesc{
        font-size: 14px;
        width: 17rem;
    }
}
@media only screen and (min-width: 375px){
    .infoCardsContainer{
        margin-left: 2.2rem;
    }
    .card{
        width: 19rem;
    }
    .cardTitle{
        margin-left: 0rem;
    }
    .cardDesc{
        width: 17.5rem;
    }
}

@media only screen and (min-width: 414px){
    .infoCardsContainer{
        margin-left: 1rem;
    }
    .card{
        width: 24rem;
    }
    .cardTitle{
        /* margin-right: 2rem;
         */
         text-align: center;
    }
    .cardDesc{
        width: 22rem;
        margin-left: .8rem;
        line-height: 36px;
    }
}

@media only screen and (min-width: 425px){
}

@media only screen and (min-width: 768px) {
    .infoCardsContainer{
        height: auto;
        flex-direction: row;
        margin-top: 3rem;
        margin-left: -.4rem;
    }
    .card{
        width: 15rem;
        height: 50rem;
    }
    .cardText{
        width: 100%;
        height: 30rem;
        /* margin-top: 70px; */
        text-align: center;
    }
    .cardTitle{
        font-size: 18px;
    }
    .cardDesc{
        font-size: 14px;
        line-height: 30px;
        width: 13rem;
        margin-left: .5rem;
    }
}

@media only screen and (min-width: 992px) {
    .infoCardsContainer{
        height: auto;
        flex-direction: row;
        margin-top: 6rem;
        margin-left: -.4rem;
    }
    .card{
        width: 19rem;
        /* height: auto; */
    }
    
    .cardImg{
        height: 14rem;
    }
    .cardImg2,.cardImg3{
        height: 13.4rem;
    }
    .cardText{
        width: 100%;
        height: 40rem;
        margin-top: 20px;
        text-align: center;
    }
    .cardTitle{
        font-size: 26px;
    }
    .cardDesc{
        font-size: 14px;
        line-height: 30px;
        width: 17rem;
        margin-left: .5rem;
    }
}

@media only screen and (min-width: 1200px) {
    .infoCardsContainer{
        height: auto;
        flex-direction: row;
        margin-top: 6rem;
        margin-left: 0rem;
    }
    .card{
        width: 24rem;
        /* height: auto; */
    }
    
    .cardImg{
        height: 14rem;
    }
    .cardImg2,.cardImg3{
        height: 13.4rem;
    }
    .cardText{
        width: 100%;
        height: 40rem;
        margin-top: 20px;
        text-align: center;
    }
    .cardTitle{
        font-size: 26px;
    }
    .cardDesc{
        font-size: 14px;
        line-height: 30px;
        width: 22rem;
        margin-left: .5rem;
    }
}

@media only screen and (min-width: 1400px){
    .card{
        width: 26rem;
    }
    .cardDesc{
        width: 24rem;
        line-height: 40px;
    }
    .cardImg2,.cardImg3{
        height: 13.8rem;
    }
}

@media only screen and (min-width: 1440px) {
    .card{
        width: 28rem;
        /* height: auto; */
    }
    .cardTitle{
        font-size: 34px;
    }
    .cardDesc{
        font-size: 22px;
        width: 26rem;
    }
    .cardImg{
        height: 20rem;
    }
    .cardImg2, .cardImg3{
        height: 13rem;
    }
}

@media only screen and (min-width: 2560px) {
    .card{
        width: 45rem;
        height: auto;
    }
    .cardImg2, .cardImg3{
        height: 20rem;
    }
    .cardTitle{
        font-size: 42px;
    }
    .cardDesc{
        font-size: 30px;
        width: 40rem;
        margin-left: 3rem;
        line-height: 46px;
    }
}
.gridView{
    height: 35rem;
    width: 64%;
    display: grid;
    grid-template-columns: auto auto auto;
    margin-left: .7rem;
}
.service{
    height: 10rem;
    width: 6rem;
    background-image:url(/static/media/tanTexture.f2ed28cb.png);
    background-position: center;
    background-size: cover;
    cursor: pointer;
    border: none;
    font-family: 'Varela Round', sans-serif;
    font-size: 14px;
    font-weight: lighter;
    text-transform: capitalize;
    color: #000; 
    margin: 2px;
}
#serviceModalTitle{
    font-family: 'Varela Round', sans-serif;
    font-size: 20px;
    font-weight: lighter;
    text-transform: capitalize;
    color: #b3a295; 
    border-bottom: none;
    margin-top: 10px;
}
#closeBtn{
    background-color: #b3a295;
    border: none;
}

#modalBodyText{
    font-family: 'Varela Round', sans-serif;
    font-size: 13px;
    margin-top: -10px;
}


/* Media Queries */

@media only screen and (max-width: 320px){
    .gridView{
        margin-top: .4rem;
        height: 32rem;
    }
}

@media only screen and (min-width: 375px){
    .gridView{
        margin-top: 1.5rem;
        margin-left: .8rem;
    }
    .service{
        width: 7rem;
    }
}

@media only screen and (min-width: 414px){
    .gridView{
        margin-left: 2rem;
    }
}

@media only screen and (min-width: 425px){
    .gridView{
        margin-top: 1.5rem;
        margin-left: .8rem;
    }
    .service{
        width: 7.8rem;
    }

}

@media only screen and (min-width: 768px){
    .gridView{
        margin-top: 6rem;
        /* margin-left: 2.4rem; */
    }
    .service{
        width: 13.3rem;
        font-size: 18px;
        margin-left: 1.4rem;
    }
}

@media only screen and (min-width: 992px){
    .gridView{
        margin-left: .5rem;
    }
    .service{
        width: 18rem;
        font-size: 18px;
        margin-left: 1.5rem;
    }
}

@media only screen and (min-width: 1200px){
    .service{
        width: 22.3rem;
        font-size: 18px;
        margin-left: 1.5rem;
    }
    .modal-content{
        width: 44rem;
    }
    #serviceModalTitle{
       font-size: 30px;
    }
    #modalBodyText{
        font-size: 20px;
        line-height: 2rem;
        padding:28px;
        text-align: center;
    }
}

@media only screen and (min-width: 1280px){
    .service{
        margin-left: 2.8rem;
    }
}

@media only screen and (min-width: 1440px){
    .gridView{
        margin-left: 2.5rem;
    }
    .service{
        height: 10rem;
        width: 26rem;
        font-size: 22px;
        margin-left: 1.5rem;
    }
}

@media only screen and (min-width: 2560px){
    .gridView{
        margin-top: 9rem;
        margin-left: 2.5rem;
        height: 60rem;
    }
    .service{
        height: 16rem;
        width: 46rem;
        font-size: 36px;
        margin-left: 4rem;
    }
}
.formContainer{
    height: 80vh;
    width: 100vw;
    margin-top: 3rem;
}

.contactWelcomeText{
    font-family: 'Varela Round', sans-serif;
    font-size: 36px;
    font-weight: lighter;
    color: #bdb3ab;
    text-transform: capitalize;
}

.welcomeContactContainer{
    width: 100%;
    text-align: center;
}

.contactContainer{
    width: 100%;
    height: auto;
}

.contactFormContainer{
    width: 85%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-left: 1.5rem;
    margin-top: .6rem;
}
.formInput{
    margin-top: 1.6rem;
    padding: 6px;
    border: none;
    border-bottom: 1px solid #bdb3ab;
}
.name{
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
    color: #000;
}
.email{
    font-size: 16px;
    font-weight: 400;
}
.contactNumber{
    font-size: 16px;
    font-weight: 400;
}
.message{
    font-size: 14px;
    height: 18vh;
    border:1px solid #bdb3ab;
    padding:12px;
}
.submitFormBtn{
    margin-top: 1.6rem;
    margin-left: 3.2rem;
    background: transparent;
    border: 1px solid #bdb3ab;
    width: 65%;
    font-family: 'Varela Round', sans-serif;
    font-size: 18px;
    color: #312f2ea1;
}
.submitFormBtn:hover{
    background: #bdb3ab;
    border: 1px solid #bdb3ab;
    color: #312f2e;
}

 /* Media Queries */
 @media only screen and (max-width: 320px){
    .contactList{
        display: none;
    }
  }


  @media only screen and (min-width: 375px){
    .formInput{
        margin-top: 3rem;
    }

    .contactList{
        display: none;
    }
  }

  @media only screen and (min-width: 411px){
    .welcomeContactContainer{
        margin-top: 6rem;
    }
    
    .contactContainer{
        margin-top: 1.5rem;
    }
    .formInput{
        margin-top: 2.8rem;
    }
    .message{
        height: 10rem;
    }

    .contactList{
        display: none;
    }
    .submitFormBtn{
        margin-left: 4rem;
    }
  }

  @media only screen and (min-width: 425px){
   
  }

  @media only screen and (min-width: 768px){
    .contactContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    
    .contactList{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 2rem;
    }
    .contactOption{
        font-family: 'Varela Round', sans-serif;
        font-size: 16px;
        color: #6e6863;
    }
    .message{
        height: 16rem;
    }
    .submitFormBtn{
        width: 40%;
        margin-left: 12rem;
    }
  }

  @media only screen and (min-width: 834px){
    
  }

  @media only screen and (min-width: 1024px){
    .submitFormBtn{
        margin-left: 17rem;
    }
  }

  @media only screen and (min-width: 1280px){
    .contactOption{
       font-size: 18px;
    }
    .submitFormBtn{
        width: 20%;
        margin-left: 54.5rem;
    }
  }
  @media only screen and (min-width: 1400px){
    
  }

  @media only screen and (min-width: 1425px){
   .submitFormBtn{
       margin-left: 60.5rem;
   }
}
  @media only screen and (min-width: 1440px){
     
  }
  @media only screen and (min-width: 2560px){

    .contactWelcomeText{
        font-size: 56px;
    }

    .contactOption{
        font-size: 26px;
    }
    
    .name, .email, .contactNumber{
        text-transform: capitalize;
        font-size: 26px;
        font-weight: 400;
        color: #000;
    }
    .message{
        font-size: 24px;
        height: 28vh;
    }
    .submitFormBtn{
        margin-left: 115.5rem;
        width: 15%;
        height: 3rem;
    }
 }
.footerContainer{
    width: 100%;
    text-align:center;
    background-color:  #bdb3ab;
}
.footerCopyRight{
    font-family: 'Varela Round', sans-serif;
    padding: 10px 0 10px 0;
    margin-bottom: 0;
    font-weight: 300;
    text-transform: capitalize;
}

/* Media Queries */
@media only screen and (max-width: 320px){
    
}


@media only screen and (min-width: 411px){
 
}

@media only screen and (min-width: 425px){
 
}

@media only screen and (min-width: 768px){
 
}

@media only screen and (min-width: 834px){
  
}

@media only screen and (min-width: 1024px){
  
}

@media only screen and (min-width: 1280px){
  .footerContainer{
      margin-top: 10rem;
  }
}
@media only screen and (min-width: 1400px){
  .footerContainer{
      margin-top: 4rem;
  }
}

@media only screen and (min-width: 1425px){
 
}
@media only screen and (min-width: 1440px){
   
}
@media only screen and (min-width: 2560px){
    .footerCopyRight{
        font-size: 34px;
    }
}
