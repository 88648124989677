@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');


#appContainer{
    padding-right: 0px;
    padding-left: 0px;
}
.gridDisplay{
    display: grid;
    grid-template-columns: auto;
    grid-gap: 16px;
    /* grid-row-gap: 10px; */
    width: 100%;
}

