@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');


.aboutUsContainer{
    width: 100%;
    margin-top: 4rem;
}
.aboutUsWrapper{
    height: 15rem;
    width: 90vw;
    text-align: center;
    margin-top: 3rem;
    margin-left: 1rem;
    box-shadow: 4px 20px 20px 20px rgba(117, 117, 117, 0.178);
    padding-top:2rem;
    background: url(../../Images/tanTexture.png);
    background-position: top;
    background-size: cover;
}
.aboutUsTitle{
    font-family: 'Varela Round', sans-serif;
    font-weight: 400;
    font-size: 12px;
}

.aboutUs, .aboutUs2{
    font-family: 'Varela Round', sans-serif;
    font-size: 12px;
}

@media only screen and (max-width: 320px){
    .aboutUsWrapper{
       height: 15rem;
    }
}

@media only screen and (min-width: 375px){
    .aboutUsWrapper{
        padding: 10px;
    }
    .aboutUsTitle{
        margin-top: 1rem;
    }
    .aboutUs, .aboutUs2{
        margin-top: 2rem;
    }
}

@media only screen and (min-width: 414px){
    .aboutUsWrapper{
       margin-left: 1.3rem;
    }
}

@media only screen and (min-width: 425px){
    .aboutUsWrapper{
       margin-left: 1rem;
    }
}

@media only screen and (min-width: 600px) {
    .aboutUsWrapper{
        width: 94vw;
        margin-left: .7rem;
    }
}

@media only screen and (min-width: 768px) {
    .aboutUsWrapper{
        margin-left: 1rem;
        height: 18rem;
    }
    .aboutUsTitle{
        font-size: 22px;
    }
    
    .aboutUs, .aboutUs2{
        font-size: 18px;
    }
}

@media only screen and (min-width: 992px) {
    .aboutUsWrapper{
        height: 20rem;
        margin-left: 1.5rem;
    }
    .aboutUsTitle{
        font-size: 24px;
    }
    
    .aboutUs, .aboutUs2{
        font-size: 20px;
        margin-top: 2.6rem;
        margin-left: 6rem;
        width: 44rem;
    }
}

@media only screen and (min-width: 1200px) {
    .aboutUsWrapper{
        height: 20rem;
        margin-left: 2.4rem;
    }
    .aboutUsTitle{
        font-size: 30px;
    }
    
    .aboutUs, .aboutUs2{
        font-size: 24px;
        margin-top: 2.6rem;
        margin-left: 4rem;
        width: 58rem;
    }
}

@media only screen and (min-width: 1400px){
    .aboutUsWrapper{
        height: 24rem;
        margin-left: 4rem;
    }
    .aboutUs, .aboutUs2{
        margin-left: 10rem;
    }
}


@media only screen and (min-width: 1440px) {
    .aboutUsWrapper{
        height: 24rem;
        margin-left: 2.8rem;

    }
    .aboutUsTitle{
        font-size: 32px;
    }
    
    .aboutUs, .aboutUs2{
        font-size: 26px;
        margin-top: 2.6rem;
        margin-left: 8.2rem;
        width: 64rem;
    }
}

@media only screen and (min-width: 2560px) {
    .aboutUsWrapper{
        height: 36rem;
        margin-left: 15rem;
        width: 80vw;
    }
    .aboutUsTitle{
        font-size: 50px;
        margin-top: 4rem;
    }
    
    .aboutUs, .aboutUs2{
        font-size: 36px;
        margin-top: 4rem;
        margin-left: 18rem;
        width: 94rem;
    }
}