@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');


.formContainer{
    height: 80vh;
    width: 100vw;
    margin-top: 3rem;
}

.contactWelcomeText{
    font-family: 'Varela Round', sans-serif;
    font-size: 36px;
    font-weight: lighter;
    color: #bdb3ab;
    text-transform: capitalize;
}

.welcomeContactContainer{
    width: 100%;
    text-align: center;
}

.contactContainer{
    width: 100%;
    height: auto;
}

.contactFormContainer{
    width: 85%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-left: 1.5rem;
    margin-top: .6rem;
}
.formInput{
    margin-top: 1.6rem;
    padding: 6px;
    border: none;
    border-bottom: 1px solid #bdb3ab;
}
.name{
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
    color: #000;
}
.email{
    font-size: 16px;
    font-weight: 400;
}
.contactNumber{
    font-size: 16px;
    font-weight: 400;
}
.message{
    font-size: 14px;
    height: 18vh;
    border:1px solid #bdb3ab;
    padding:12px;
}
.submitFormBtn{
    margin-top: 1.6rem;
    margin-left: 3.2rem;
    background: transparent;
    border: 1px solid #bdb3ab;
    width: 65%;
    font-family: 'Varela Round', sans-serif;
    font-size: 18px;
    color: #312f2ea1;
}
.submitFormBtn:hover{
    background: #bdb3ab;
    border: 1px solid #bdb3ab;
    color: #312f2e;
}

 /* Media Queries */
 @media only screen and (max-width: 320px){
    .contactList{
        display: none;
    }
  }


  @media only screen and (min-width: 375px){
    .formInput{
        margin-top: 3rem;
    }

    .contactList{
        display: none;
    }
  }

  @media only screen and (min-width: 411px){
    .welcomeContactContainer{
        margin-top: 6rem;
    }
    
    .contactContainer{
        margin-top: 1.5rem;
    }
    .formInput{
        margin-top: 2.8rem;
    }
    .message{
        height: 10rem;
    }

    .contactList{
        display: none;
    }
    .submitFormBtn{
        margin-left: 4rem;
    }
  }

  @media only screen and (min-width: 425px){
   
  }

  @media only screen and (min-width: 768px){
    .contactContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    
    .contactList{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 2rem;
    }
    .contactOption{
        font-family: 'Varela Round', sans-serif;
        font-size: 16px;
        color: #6e6863;
    }
    .message{
        height: 16rem;
    }
    .submitFormBtn{
        width: 40%;
        margin-left: 12rem;
    }
  }

  @media only screen and (min-width: 834px){
    
  }

  @media only screen and (min-width: 1024px){
    .submitFormBtn{
        margin-left: 17rem;
    }
  }

  @media only screen and (min-width: 1280px){
    .contactOption{
       font-size: 18px;
    }
    .submitFormBtn{
        width: 20%;
        margin-left: 54.5rem;
    }
  }
  @media only screen and (min-width: 1400px){
    
  }

  @media only screen and (min-width: 1425px){
   .submitFormBtn{
       margin-left: 60.5rem;
   }
}
  @media only screen and (min-width: 1440px){
     
  }
  @media only screen and (min-width: 2560px){

    .contactWelcomeText{
        font-size: 56px;
    }

    .contactOption{
        font-size: 26px;
    }
    
    .name, .email, .contactNumber{
        text-transform: capitalize;
        font-size: 26px;
        font-weight: 400;
        color: #000;
    }
    .message{
        font-size: 24px;
        height: 28vh;
    }
    .submitFormBtn{
        margin-left: 115.5rem;
        width: 15%;
        height: 3rem;
    }
 }