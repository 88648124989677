.gridView{
    height: 35rem;
    width: 64%;
    display: grid;
    grid-template-columns: auto auto auto;
    margin-left: .7rem;
}
.service{
    height: 10rem;
    width: 6rem;
    background-image:url(../../Images/tanTexture.png);
    background-position: center;
    background-size: cover;
    cursor: pointer;
    border: none;
    font-family: 'Varela Round', sans-serif;
    font-size: 14px;
    font-weight: lighter;
    text-transform: capitalize;
    color: #000; 
    margin: 2px;
}
#serviceModalTitle{
    font-family: 'Varela Round', sans-serif;
    font-size: 20px;
    font-weight: lighter;
    text-transform: capitalize;
    color: #b3a295; 
    border-bottom: none;
    margin-top: 10px;
}
#closeBtn{
    background-color: #b3a295;
    border: none;
}

#modalBodyText{
    font-family: 'Varela Round', sans-serif;
    font-size: 13px;
    margin-top: -10px;
}


/* Media Queries */

@media only screen and (max-width: 320px){
    .gridView{
        margin-top: .4rem;
        height: 32rem;
    }
}

@media only screen and (min-width: 375px){
    .gridView{
        margin-top: 1.5rem;
        margin-left: .8rem;
    }
    .service{
        width: 7rem;
    }
}

@media only screen and (min-width: 414px){
    .gridView{
        margin-left: 2rem;
    }
}

@media only screen and (min-width: 425px){
    .gridView{
        margin-top: 1.5rem;
        margin-left: .8rem;
    }
    .service{
        width: 7.8rem;
    }

}

@media only screen and (min-width: 768px){
    .gridView{
        margin-top: 6rem;
        /* margin-left: 2.4rem; */
    }
    .service{
        width: 13.3rem;
        font-size: 18px;
        margin-left: 1.4rem;
    }
}

@media only screen and (min-width: 992px){
    .gridView{
        margin-left: .5rem;
    }
    .service{
        width: 18rem;
        font-size: 18px;
        margin-left: 1.5rem;
    }
}

@media only screen and (min-width: 1200px){
    .service{
        width: 22.3rem;
        font-size: 18px;
        margin-left: 1.5rem;
    }
    .modal-content{
        width: 44rem;
    }
    #serviceModalTitle{
       font-size: 30px;
    }
    #modalBodyText{
        font-size: 20px;
        line-height: 2rem;
        padding:28px;
        text-align: center;
    }
}

@media only screen and (min-width: 1280px){
    .service{
        margin-left: 2.8rem;
    }
}

@media only screen and (min-width: 1440px){
    .gridView{
        margin-left: 2.5rem;
    }
    .service{
        height: 10rem;
        width: 26rem;
        font-size: 22px;
        margin-left: 1.5rem;
    }
}

@media only screen and (min-width: 2560px){
    .gridView{
        margin-top: 9rem;
        margin-left: 2.5rem;
        height: 60rem;
    }
    .service{
        height: 16rem;
        width: 46rem;
        font-size: 36px;
        margin-left: 4rem;
    }
}