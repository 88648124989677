@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

.infoCardsContainer{
    width: 100%;
    height: 120rem;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-evenly;
    margin-top: 8rem;
    margin-left: 2rem;
    /* z-index: 1; */
}
.card{
    height: 42rem;
    width: 16rem;
    border: 2px solid #F2E7DC;
    /* margin-left: 1.1rem; */
    margin-top: 4rem;
    text-align: center;
}

.cardImg{
    width: 100%;
    height: 14rem;

}
.cardImg1{
    background-image: linear-gradient(to right, #30303078, #3030306d),url(../../Images/hispanic\ senior.jpg);
    background-position: top;
    background-size: cover;
    
}
.cardImg2{
    background-image: linear-gradient(to right, #30303078, #3030306d),url(../../Images/stethoscope2.jpg);
    background-position: top;
    background-size: cover;
}
.cardImg3{
    background-image: linear-gradient(to right, #30303078, #3030306d),url(../../Images/asian\ senior.jpg);
    background-position: top;
    background-size: cover;
}

.cardText{
    width: 100%;
    height: 40rem;
    margin-top: 20px;
    text-align: center;
}
.cardTitle{
    font-family: 'Varela Round', sans-serif;
    font-weight: 400;
    font-size: 22px;
    margin-left: 1.5rem;
    margin-top: 20px;
}
.cardDesc{
    font-family: 'Varela Round', sans-serif;
    font-size: 14px;
    line-height: 30px;
    width: 15rem;
    margin-left: .3rem;
    margin-top: 30px;
}

/* media Queries */

@media only screen and (max-width: 320px){
    .card{
        width: 18rem;
    }
    .infoCardsContainer{
        margin-left: 1rem;
    }
    .cardTitle{
        margin-left: 0rem;
    }
    .cardDesc{
        font-size: 14px;
        width: 17rem;
    }
}
@media only screen and (min-width: 375px){
    .infoCardsContainer{
        margin-left: 2.2rem;
    }
    .card{
        width: 19rem;
    }
    .cardTitle{
        margin-left: 0rem;
    }
    .cardDesc{
        width: 17.5rem;
    }
}

@media only screen and (min-width: 414px){
    .infoCardsContainer{
        margin-left: 1rem;
    }
    .card{
        width: 24rem;
    }
    .cardTitle{
        /* margin-right: 2rem;
         */
         text-align: center;
    }
    .cardDesc{
        width: 22rem;
        margin-left: .8rem;
        line-height: 36px;
    }
}

@media only screen and (min-width: 425px){
}

@media only screen and (min-width: 768px) {
    .infoCardsContainer{
        height: auto;
        flex-direction: row;
        margin-top: 3rem;
        margin-left: -.4rem;
    }
    .card{
        width: 15rem;
        height: 50rem;
    }
    .cardText{
        width: 100%;
        height: 30rem;
        /* margin-top: 70px; */
        text-align: center;
    }
    .cardTitle{
        font-size: 18px;
    }
    .cardDesc{
        font-size: 14px;
        line-height: 30px;
        width: 13rem;
        margin-left: .5rem;
    }
}

@media only screen and (min-width: 992px) {
    .infoCardsContainer{
        height: auto;
        flex-direction: row;
        margin-top: 6rem;
        margin-left: -.4rem;
    }
    .card{
        width: 19rem;
        /* height: auto; */
    }
    
    .cardImg{
        height: 14rem;
    }
    .cardImg2,.cardImg3{
        height: 13.4rem;
    }
    .cardText{
        width: 100%;
        height: 40rem;
        margin-top: 20px;
        text-align: center;
    }
    .cardTitle{
        font-size: 26px;
    }
    .cardDesc{
        font-size: 14px;
        line-height: 30px;
        width: 17rem;
        margin-left: .5rem;
    }
}

@media only screen and (min-width: 1200px) {
    .infoCardsContainer{
        height: auto;
        flex-direction: row;
        margin-top: 6rem;
        margin-left: 0rem;
    }
    .card{
        width: 24rem;
        /* height: auto; */
    }
    
    .cardImg{
        height: 14rem;
    }
    .cardImg2,.cardImg3{
        height: 13.4rem;
    }
    .cardText{
        width: 100%;
        height: 40rem;
        margin-top: 20px;
        text-align: center;
    }
    .cardTitle{
        font-size: 26px;
    }
    .cardDesc{
        font-size: 14px;
        line-height: 30px;
        width: 22rem;
        margin-left: .5rem;
    }
}

@media only screen and (min-width: 1400px){
    .card{
        width: 26rem;
    }
    .cardDesc{
        width: 24rem;
        line-height: 40px;
    }
    .cardImg2,.cardImg3{
        height: 13.8rem;
    }
}

@media only screen and (min-width: 1440px) {
    .card{
        width: 28rem;
        /* height: auto; */
    }
    .cardTitle{
        font-size: 34px;
    }
    .cardDesc{
        font-size: 22px;
        width: 26rem;
    }
    .cardImg{
        height: 20rem;
    }
    .cardImg2, .cardImg3{
        height: 13rem;
    }
}

@media only screen and (min-width: 2560px) {
    .card{
        width: 45rem;
        height: auto;
    }
    .cardImg2, .cardImg3{
        height: 20rem;
    }
    .cardTitle{
        font-size: 42px;
    }
    .cardDesc{
        font-size: 30px;
        width: 40rem;
        margin-left: 3rem;
        line-height: 46px;
    }
}