.landingContainer {
    text-align: center;
    width:100%;
  }
  /* Anime Div Style */
  .animeDiv0{
    height: 200px;
  }
   .companyLogo{
     height: 20rem;
     width: 20rem;
   }
   .logoContainer{
    margin-top: 30%;
   }
   /* Anime Home Button Container div */
   .animeHmeBtnDiv0{
      margin-top:40px;
      margin-left: 100px;
      width: 100%;
   }
  
   .landingHomeBtn{
     text-transform: capitalize;
     font-size:14px;
     text-decoration: none;
     color: #8b775d;
   }

   /* Media Queries */
   @media only screen and (max-width: 320px){
     .logoContainer{
       margin-top: 6rem;
     }
   }


   @media only screen and (min-width: 411px){
    
    .logoContainer{
      margin-top: 17rem;
    }
   }

   @media only screen and (min-width: 425px){
    
    .logoContainer{
      width: 0px;
      margin-left: 20rem;
    }
   }

   @media only screen and (min-width: 768px){
    .animeDiv0{
      height: 42vh;
    }  
    
    .companyLogo{
        height: 40rem;
        width: 40rem;
      }

      .logoContainer{
        margin-left: 4.4rem;
      }
      .animeHmeBtnDiv0{
        margin-top: 0px;
        margin-left: 27rem;
        width: 200px;
      }
      .landingHomeBtn{
        font-size: 20px;
      }
   }

   @media only screen and (min-width: 834px){
     .logoContainer{
       margin-left: 6.5rem;
     }
   }

   @media only screen and (min-width: 1024px){
     .animeDiv0{
       height: 34vh;
     }
     .logoContainer{
       margin-top: 20rem;
       margin-left: 12rem;
     }
   }

   @media only screen and (min-width: 1280px){
     .animeDiv0{
       height: 48vh;
     }
     .logoContainer{
       margin-top: 12rem;
       margin-left: 20rem;
     }
   }
   @media only screen and (min-width: 1400px){
      .animeDiv0{
        height: 60vh;
      }

      .logoContainer{
        margin-top: 2rem;
        margin-left: 20rem;
      }
   }

   @media only screen and (min-width: 1425px){
    .animeDiv0{
      height: 60vh;
    }

    .logoContainer{
      margin-top: 4rem;
      margin-left: 20rem;
    }
 }
   @media only screen and (min-width: 1440px){
      .logoContainer{ 
        margin-top: 4rem;
        margin-left: 24rem;
      }
   }
   @media only screen and (min-width: 2560px){
    .animeDiv0{
      height: 45vh;
    }    
    
    .logoContainer{
        margin-top: 18rem;
        margin-left: 50rem;
      }
      .companyLogo{
        height:60vh;
        width: 60vh;
      }
      .animeHmeBtnDiv0{
        margin-left: 40rem;
      }
      .landingHomeBtn{
        font-size: 34px;
      }
  }
   /* @media only screen and (min-width: 3840px){
      .animeDiv0{
        height: 20vh;
      }
      .logoContainer{
        margin-top: 38rem;
      }
   } */